import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material'
import css from './ComoConoceSelector.module.css'

const ComoConoceSelector = ({ className, name, label, value, onChange })=> (
  <FormControl className={`${className} ${css.main}`} variant='standard'>
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      <ListSubheader>Referidos</ListSubheader>
      <MenuItem value='RECOMENDACION'>Recomendación</MenuItem>
      <MenuItem value='FAMILIAR'>Familiar</MenuItem>
      <MenuItem value='ANTIGUO_ALUMNO'>Antiguo alumno</MenuItem>
      <ListSubheader>Redes Sociales</ListSubheader>
      <MenuItem value='FACEBOOK'>Facebook</MenuItem>
      <MenuItem value='INSTAGRAM'>Instagram</MenuItem>
      <MenuItem value='TIKTOK'>Tik Tok</MenuItem>
      <ListSubheader>Google</ListSubheader>
      <MenuItem value='GOOGLE'>Buscador de Google</MenuItem>
      <MenuItem value='GOOGLE_MAPS'>Google Maps</MenuItem>
      <ListSubheader>Punto de venta</ListSubheader>
      <MenuItem value='CALLE'>Tienda de calle</MenuItem>
      <MenuItem value='FOLLETO'>Folleto</MenuItem>
      <MenuItem value='CARTEL'>Cartel exterior</MenuItem>
      <ListSubheader>Otros</ListSubheader>
      <MenuItem value='ACUERDOS_CORPORATIVOS'>Acuerdos corporativos</MenuItem>
      <MenuItem value='OTRA'>Otra</MenuItem>


    </Select>
  </FormControl>
)

export default ComoConoceSelector
