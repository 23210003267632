import { GET, POST, PUT, DELETE } from '../request'

export const getTarifasAlumno = (alumnoId) => GET('/api/agora/alumnos/contabilidad/tarifa-alumno/list/alumno', { alumno: alumnoId })

export const getTarifaAlumno = (data = {}) => GET('/api/agora/alumnos/contabilidad/tarifa-alumno', data)

export const createTarifaAlumno = ({
  alumno,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  tarifa,
  mes,
  codigo_promocional,
}) => POST('/api/agora/alumnos/contabilidad/tarifa-alumno', {
  alumno,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  tarifa,
  mes,
  codigo_promocional,
})

export const copyTarifaAlumno = ({ alumno, mes }) => POST('/api/agora/alumnos/contabilidad/tarifa-alumno/copy', { alumno, mes })

export const updateTarifaAlumno = ({
  id,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  tarifa
}) => PUT('/api/agora/alumnos/contabilidad/tarifa-alumno', {
  id,
  centro_estudios,
  formacion,
  curso,
  objetivo,
  tarifa
})

export const deleteTarifaAlumno = (id) => DELETE('/api/agora/alumnos/contabilidad/tarifa-alumno', { id })
