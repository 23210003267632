import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, IconButton, Typography } from '@mui/material'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { dateToString } from '../../../../utils/date'
import { getCertificadosAlumno, createCertificadoAlumno } from '../../../../utils/api/alumnos'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import useAlumno from '../../../../hooks/useAlumno'
import { getCertificadoAlumno } from '../../../../utils/documents'
import ModalNuevoCertificado from '../../../../modals/ModalNuevoCertificado/ModalNuevoCertificado'
import css from './Certificados.module.css'
import { dateSort } from '../../../../utils/table'

const Certificados = ()=> {
  
   const { id } = useAlumno()
   const notification = useNotification()
   const queryClient = useQueryClient()

  const [open, setOpen] = useState(false)

  const { data: certificadosAlumno = [], isLoading: cargandoCertificados} = useQuery({
    queryKey: ['alumnos', 'certificado-alumno', 'list', 'alumno', id],
    queryFn: () => getCertificadosAlumno({ alumno: id })
      .then(datos=> datos.map(certificado=> ({
        ...certificado,
        fecha: dateToString(certificado.fecha),
        fechaInicio: dateToString(certificado.fechaInicio),
        fechaFin: dateToString(certificado.fechaFin), 
      })))
      .catch(err=> {
        notification.error({ title: 'Error obteniendo los certificados', content: err })
        return []
      })
  })

  const { isPending: isCertificadoCreating, mutate: crearCertificado} = useMutation({
    mutationFn: createCertificadoAlumno,
    onSuccess: ()=> {
      notification.success({ title: 'Certificado creado', content: 'Se ha creado el certificado correctamente' })
      queryClient.invalidateQueries({ 
        queryKey: ['alumnos', 'certificado-alumno', 'list', 'alumno', id] 
      })
      setOpen(false)
    },
    onError: (err)=> {
      notification.error({ title: 'Error creando certificado', content: err })
    }
  })
  
  const handleViewCertificado = certificado => {
    const url = getCertificadoAlumno({
      academia: certificado.centro,
      fecha: certificado.fecha,
      alumno: certificado.alumno,
      formacion: certificado.formacion,
      fecha_inicio: certificado.fechaInicio,
      fecha_fin: certificado.fechaFin,
      curso_academico: certificado.concepto,
      pagador: certificado.nombrePagador,
      identificacion_pagador: certificado.identificacionPagador,
      desembolso: certificado.importePagado,
    })
    window.open(url, '_blank')
  }

  const handleSubmitCertificado = datos => {
    if (isCertificadoCreating) return
    crearCertificado({
      alumno: id,
      concepto: datos.concepto,
      fecha_comienzo: datos.fecha_comienzo,
      fecha_fin: datos.fecha_fin,
      nombre_pagador: datos.nombre_pagador,
      identificacion_pagador: datos.identificacion_pagador
    })
  }

  return (
    <PageLoading isLoading={cargandoCertificados}>
      <div className={css.header}>
        <Typography variant='h6' align='center' padding='20px'>
          Certificados emitidos
        </Typography>
        <Button variant='outlined' size='small' onClick={() => setOpen(true)}>
          Emitir certificado
        </Button>
      </div>
      <InformationTable
        size='small'
        details={[
          { title: 'Fecha de emisión', key: 'fecha', sortFunction: dateSort },
          { title: 'Formación', key: 'formacion', },
          { title: 'Centro', key: 'centro', },
          { title: 'Opciones', key: 'opciones', align: 'center', sortDisabled: true }
        ]}
        data={certificadosAlumno.map(certificado => ({
          fecha: certificado.fecha,
          formacion: certificado.formacion || '--',
          centro: certificado.centro || '--',
          opciones: (
            <IconButton
              color='primary'
              size='small'
              onClick={() => handleViewCertificado(certificado)}
            >
              <i className='material-icons'>description</i>
            </IconButton>
          )
        }))}
      />
      <ModalNuevoCertificado
        open={open}
        onClose={()=> setOpen(false)}
        onSubmit={handleSubmitCertificado}
      />
    </PageLoading>
  )
}


export default Certificados
