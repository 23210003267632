import { useState } from "react"
import MesRecienteSelector from "../../components/form/MesRecienteSelector/MesRecienteSelector"
import { monthToString } from "../../utils/date"
import { Chip, Typography } from "@mui/material"
import { getAuth } from "../../utils/auth"
import Tabs from "../../components/Tabs/Tabs"
import Cajas from "./tabs/Cajas/Cajas"
import Ventas from "./tabs/Ventas/Ventas"
import Ingresos from "./tabs/Ingresos/Ingresos"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import { useQuery } from "@tanstack/react-query"
import { getBalanceMes } from "../../utils/api/balanceMensual"
import css from './Contabilidad.module.css'
import { format } from "../../utils/math"

const Contabilidad = ()=> {

  const notification = useNotification()

  const userData = getAuth()

  const [mes, setMes] = useState(monthToString(new Date()))

  const { isBalanceLoading, data: balance=null } = useQuery({
    queryKey: ['interno', 'contabilidad', 'balance-menusal', 'month', mes], 
    queryFn: ()=> getBalanceMes({ mes })
      .then(datos=> ({
        objetivo: format(datos.objetivo || 0),
        ventas: format(datos.ventas || 0),
        ingresos: format(datos.ingresos || 0),
        deuda: format(datos.deuda || 0),
      }))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los totales del mes', content: err })
        return null
      })
  })

  return (
    <>
      <div className={css.header}>
        <Typography variant='h6'>Contabilidad de {userData.centro}</Typography>
        <MesRecienteSelector
          className={css.mes}
          name='mes'
          label='Mes'
          value={mes}
          forwardOptions={1}
          backwardOptions={6}
          onChange={e=> setMes(e.target.value)}
        />
      </div>
      {!isBalanceLoading && balance && (
        <div className={css.totales}>
          <Chip className={css.chip} label={`Ventas realizadas: ${balance.ventas}€`} color="primary" variant="outlined" />
          <Chip className={css.chip} label={`Deuda: ${balance.deuda}€`} color="error" variant="outlined" />
          <Chip className={css.chip} label={`Ingresos: ${balance.ingresos}€`} color="secondary" variant="outlined" />
        </div> 
      )}
      <Tabs
        className={css.tabs}
        tabs={[
          { name: 'Ventas', content: <Ventas mes={mes} />},
          { name: 'Ingresos', content: <Ingresos mes={mes} /> },
          { name: 'Cajas', content: <Cajas mes={mes} /> },
        ]}
      />
    </>
  )
}

export default Contabilidad
