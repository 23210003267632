import { useState } from 'react'
import { IconButton, Link } from '@mui/material'
import InformationTable from '../../../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import { dateToString } from '../../../../utils/date'
import { format, formatForExport } from '../../../../utils/math'
import { getPagosMes } from '../../../../utils/api/contabilidad'
import { dateSort, numberSort, textFilter } from '../../../../utils/table'
import css from './Ventas.module.css'

const Ventas = ({ mes })=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: pagoList=[] } = useQuery({
    queryKey: ['contabilidad', 'pago', 'list', 'month', mes], 
    queryFn: ()=> getPagosMes(mes)
      .then(datos=> datos
        .sort((a, b)=> b.fecha - a.fecha)
        .map(pago=> ({
          ...pago,
          fecha: dateToString(pago.fecha),
        }))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las ventas', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: [
        'Alumno',
        'Fecha',
        'Concepto',
        'Importe',
        'Importe Pendiente',
        'Tipo de Pago',
      ],
      data: pagoList,
      transform: c=> ([
        c.alumno,
        c.fecha,
        c.concepto,
        formatForExport(c.importePago),
        formatForExport(c.importeRestante),
        c.tipoPago,
      ])
    })
    downloadCSVFile(content, `pagos-${mes}`)
  }

  const filterSearch = pago=> {
    return textFilter({ 
      object: pago, 
      fields: ['alumno', 'fecha', 'concepto', 'tipoPago', 'importe', 'importeRestante'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Ventas del mes'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', sortKey: 'alumnoNombre' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort },
          { title: 'Importe', key: 'importe', sortFunction: numberSort },
          { title: 'Importe restante', key: 'importeRestante', sortFunction: numberSort },
          { title: 'Tipo de pago', key: 'tipoPago' },
          { title: 'Concepto', key: 'concepto' },
        ]}
        data={pagoList.filter(filterSearch).map((pago) => ({
          alumnoNombre: pago.alumno,
          alumno: (
            <Link
              className={css.link}
              href={`/alumnos/${pago.alumnoId}/ficha`}
            >
              {pago.alumno}
            </Link>
          ),
          fecha: pago.fecha,
          importe: `${format(pago.importePago)} €`,
          importeRestante: (
            <span 
              className={css.deuda} 
              data-status={pago.importeRestante > 0 ? 'deuda' : 'pagado'}
            >
              {format(pago.importeRestante)} €
            </span>
          ),
          tipoPago: pago.tipo,
          concepto: pago.concepto,
        }))}
        isFetching={isLoading}
      />
    </PageLoading>
  )
}

export default Ventas