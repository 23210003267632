import { GET, POST, PUT } from '../request'

export const getPagosAlumno = ({ alumno }) => GET('/api/agora/contabilidad/pago/list/alumno', { alumno })

export const getPagosMes = mes => GET('/api/agora/contabilidad/pago/list/month', { mes })

export const getPagosPendientesMes = mes => GET('/api/agora/contabilidad/pago/list/pending/month', { mes })

export const getPagosPendientesRecientes = () => GET('/api/agora/contabilidad/pago/list/pending/recent')

export const getIngresosAlumno = (data = {}) => GET('/api/agora/alumnos/contabilidad/ingreso-alumno/list/alumno', data)

export const createPago = ({ alumno, concepto, importe, observaciones }) => POST('/api/agora/contabilidad/pago', { alumno, concepto, importe, observaciones })

export const createIngresoPago = ({ id, importe, forma_pago }) => PUT(`/api/agora/contabilidad/pago/ingreso-alumno`, { id, importe, forma_pago })

export const updateCodigoPromocional = ({ tipo, data = {} }) => PUT(`/api/agora/contabilidad/${tipo}/codigo-promocional`, data)
