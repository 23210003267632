import { Autocomplete, TextField } from '@mui/material'

const CONTACTOS = [
  'Propio alumno',
  'Padre',
  'Madre',
  'Hermano',
  'Hermana',
  'Tío',
  'Tía',
  'Abuelo',
  'Abuela',
  'Pareja',
  'Familiar',
  'Amigo',
  'Amiga',
  'Otro',
]

const TutorSelector = ({
  name,
  className='',
  label='Relación',
  value,
  onChange,
})=> (
  <Autocomplete
    className={className}
    name={name}
    id={name}
    value={value}
    onChange={onChange}
    options={CONTACTOS}
    freeSolo
    renderInput={params => (
      <TextField
        {...params}
        fullWidth
        variant='standard'
        margin='dense'
        label={label}
      />
    )}
  />
)
export default TutorSelector
